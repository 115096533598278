import React from 'react'
import PromotionCategory from 'templates/promotion-category'
import { graphql } from 'gatsby'

const PromotionSubcategory = props => {
  return (
    <PromotionCategory
      {...props}
      category={props.pageContext.category}
      subcategory={props.pageContext.subcategory}
    />
  )
}

export const pageQuery = graphql`
  query promotionSubcategory($subcategory: String!) {
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }) {
      nodes {
        categoryList
        description
        disclaimer
        endDateString: endDate(formatString: "MMMM DD, YYYY")
        excerpt
        id
        image: localImage {
          ...SharpImage640
        }
        productList
        seriesList
        slug
        subcategoryList
        subTitle
        tags
        title
      }
    }
  }
`

export default PromotionSubcategory
